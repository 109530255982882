<template>
  <div>
    <Header :show="true"/>
    <div class="container">
        <div class="col back cursor flexStart" @click="$router.back()">
            <img src="@/assets/images/bottom.png" alt="">
            返回
        </div>
        
        <div class="row">
            <div class="col-lg-5 col-sm-12" style="margin-right:50px">
                <div class="row">
                    <div class="bigImg mb20">
                        <img :src="bigImg" alt="" style="height:100%">
                    </div>
                </div>
                <div class="row">
                    <div class="smallImg flexStart">
                        <img :src="item" alt="" style="height:100%" v-for="(item,inx) in obj.images" :key="inx" @click="bigImg=item">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-sm-12">
                <p class="defaultWord active mb40">{{obj.title}}</p>
                <p class="defaultWord ">编号：{{obj.bh}}</p>
                <p class="defaultWord ">类别：{{obj.lb}}</p>
                <p class="defaultWord ">年代：{{obj.nd}}</p>
                <p class="defaultWord ">作者：{{obj.zz}}</p>
                <p class="defaultWord mb40">规格：{{obj.gg}}</p>
                <p class="defaultWord mb40">{{obj.js}}</p>
                <p class="defaultWord" style="font-size:.8rem">© SIA插画博物馆 <span style="color:#ddb53d" class="cursor" @click="$router.push('/detailWord')">版权所有</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="defaultWord mt20 pb10">
                    <span>
                        相关推荐
                    </span>
                </p>
                <div id="group" class="collapse show">
                    <!--            row-cols-auto-->
                    <div class="row row-cols-auto">
                        <div class="col " v-for="item in memberLogo" :key="item.id">
                            <div class="memHei active">
                                <img :src="require(`@/assets/images/h${item.id}.png`)" alt="" style="width: 7rem">
                            </div>
                            <div class="mp1">{{item.name}}</div>
                            <div class="mp2">{{item.desc}}</div>
                        </div>
                        <div class="col">
                            <div class="memHei active">
        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer :footBox="2"></Footer>
  </div>
</template>
<script>
export default {
  name: "header",
  data(){
    return{
        obj:'',
        bigImg:'',
        memberLogo: [
            {
                id: 1,
                name: '泱泱文化',
                desc: '会长单位'
            }, {
                id: 2,
                name: '视觉中国',
                desc: '副会长单位'
            }, {
                id: 3,
                name: '华为主题',
                desc: '理事单位'
            }, 
        ]
    }
  },
  methods:{
      //获取藏品详情
      get_index_cpdes(id) {
          this.$get('/api/get_index_cpdes', {id}).then(res => {
              this.obj = res.data;
              this.bigImg=res.data.images[0]
          }).catch(err => {

          })
      },
  },
  mounted(){
      this.get_index_cpdes(this.$route.params.id);
  }
}
</script>
<style scoped lang="scss">
.back{
    
    color: #4d4d4d;
    margin: 2rem 0;
    img{
        margin-right: 10px;
        width: 16px;
        transform: rotate(90deg);
    }
}
.bigImg{
    width: 100%;
    height: 23rem;
    overflow: hidden;
    padding: 10px;
        box-sizing: border-box;
        background: #f0f0eb;
}
.smallImg{
    height: 8rem;
    padding: 10px;
    box-sizing: border-box;
    background: #f0f0eb;
    overflow-x: scroll;
    img{
        margin-right: 10px;
        cursor: pointer;
    }
}
.mb40{
    margin-bottom: 40px!important;
}
.memHei {
    width: 100%;
    position: relative;
    min-width: 7rem;
    background: transparent;
}

.mp1 {
    font-size: 1rem;
    color: #afafaf;
}

.mp2 {
    font-size: .8rem;
    color: #afafaf;
}
</style>